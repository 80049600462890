import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { ProtectedRoute } from './utils/ProtectedRoute';

const Products = Loadable(lazy(() => import('views/pages/bi/products')));
const Customer = Loadable(lazy(() => import('views/pages/bi/customer')));
const Neighborhood = Loadable(lazy(() => import('views/pages/bi/neighborhood')));
const Performance = Loadable(lazy(() => import('views/pages/bi/performance')));

// ==============================|| SALES ROUTING ||============================== //

const SalesRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/sales-product',
      element: (
        <ProtectedRoute>
          <Products />
        </ProtectedRoute>
      )
    },
    {
      path: '/sales-customer',
      element: (
        <ProtectedRoute>
          <Customer />
        </ProtectedRoute>
      )
    },
    {
      path: '/sales-address',
      element: (
        <ProtectedRoute>
          <Neighborhood />
        </ProtectedRoute>
      )
    },
    {
      path: '/sales-performance',
      element: (
        <ProtectedRoute>
          <Performance />
        </ProtectedRoute>
      )
    }
  ]
};

export default SalesRoutes;
