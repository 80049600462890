import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { ProtectedRoute } from './utils/ProtectedRoute';

const InvoicesStock = Loadable(lazy(() => import('views/pages/stock/invoices')));
const ProductsStock = Loadable(lazy(() => import('views/pages/stock/product')));
const SupplyStock = Loadable(lazy(() => import('views/pages/stock/supply')));
const ExpenseStock = Loadable(lazy(() => import('views/pages/stock/expense')));
// ==============================|| SALES ROUTING ||============================== //

const StockRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'stock-invoices',
      element: (
        <ProtectedRoute>
          <InvoicesStock />
        </ProtectedRoute>
      )
    },
    {
      path: 'stock-product',
      element: (
        <ProtectedRoute>
          <ProductsStock />
        </ProtectedRoute>
      )
    },
    {
      path: 'stock-supply',
      element: (
        <ProtectedRoute>
          <SupplyStock />
        </ProtectedRoute>
      )
    },
    {
      path: 'stock-expense',
      element: (
        <ProtectedRoute>
          <ExpenseStock />
        </ProtectedRoute>
      )
    }
  ]
};

export default StockRoutes;
