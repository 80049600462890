import { IconChartHistogram } from '@tabler/icons-react';

const stock = {
  id: 'stock',
  title: 'Estoque',
  type: 'group',
  children: [
    {
      id: 'stock-invoices',
      url: '/stock-invoices',
      title: 'Notas Fiscais',
      type: 'item',
      icon: IconChartHistogram,
      breadcrumbs: false
    },
    {
      id: 'stock-product',
      url: '/stock-product',
      title: 'Produtos',
      type: 'item',
      icon: IconChartHistogram,
      breadcrumbs: false
    },
    {
      id: 'stock-supply',
      url: '/stock-supply',
      title: 'Insumos',
      type: 'item',
      icon: IconChartHistogram,
      breadcrumbs: false
    },
    {
      id: 'stock-expense',
      url: '/stock-expense',
      title: 'Despesas',
      type: 'item',
      icon: IconChartHistogram,
      breadcrumbs: false
    }
  ]
};

export default stock;
